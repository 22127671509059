import React from 'react';
import locationImg from "../../Assets/images/footer/location-one.png";
import contact from '../../Assets/images/footer/contact-one.png';
import hoursone from '../../Assets/images/footer/hours-one.png';
import logo from '../../Assets/images/logobfm.png'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Footer() {

    const { t } = useTranslation();
    const location = useLocation();

    const ShowCta = () => {
        const currentPath = location.pathname;

        // Affiche le div si l'URL correspond (par exemple "/home" ou "/about")
        if (currentPath === '/about') {
            return <div class="row pb-5">
                <div class="col-12">
                    <div class="footer-secondary__newsletter  gFadeBottom">
                        <div class="row section__row align-items-center">
                            <div class="col-12 col-sm-10 col-md-8 col-lg-8 col-xxl-5 section__col">
                                <div class="footer-secondary__newsletter-content">
                                    <h6 class="title-anim">{t('footerCtaTitle')}</h6>
                                    <h2 class="title-anim">
                                        {t('footerCtaText')}
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-4 offset-xxl-1 section__col">

                                <div class="footer-cta section__content-cta text-end">
                                    <a href="/contact" class="btn btn--primary btn-lg">{t('contactIntro2')}</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        return null;
    }

    return (
        <footer class={`footer section section--space-top `}>
            <div class="container">
                <ShowCta />
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
                        <div class="footer__single footer__single--secondary">
                            <a href="index.html">
                                <img src={logo} alt="Logo" />
                            </a>
                            <p>
                                {t('footerIntro')}
                            </p>
                            <div class="footer-cta section__content-cta">
                                <a href="/about" class="btn btn--primary">{t('footerBtnText')}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5 offset-lg-2 col-xl-4 offset-xl-0">
                        <div class="footer__single">
                            <h4>{t('footerServiceTitle')}</h4>
                            <ul>
                                <li>
                                    <a href="/bfm-movers">
                                        <i class="icon-double-arrow-right"></i>
                                        BFM Movers
                                    </a>
                                </li>
                                <li>
                                    <a href="/bfm-logistics">
                                        <i class="icon-double-arrow-right"></i>
                                        {t('BfmServ')}
                                    </a>
                                </li>
                                <li>
                                    <a href="/bfm-developpers">
                                        <i class="icon-double-arrow-right"></i>
                                        {t('BfmDev')}
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-5 offset-lg-2 col-xl-4 offset-xl-0">
                        <div class="footer__single">
                            <h4>Newsletter</h4>
                            <div class="footer__single-news">
                                <p>
                                    {t('footerNewsletterText')}
                                </p>
                                <form action="#">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            name="news-email"
                                            id="newsEmail"
                                            required
                                            placeholder={t('ContactFormInputEmail')}
                                        />
                                        <button type="submit" aria-label="subscribe to our newsletter">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </form>
                                <ul class="social">
                                    <li>
                                        <a href="https://www.facebook.com/share/asHeEo6dQ8KiGX9J/?mibextid=LQQJ4d" aria-label="social media">
                                            <i class="fa-brands fa-facebook"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.linkedin.com/company/bfminc/" aria-label="social media">
                                            <i class="fa-brands fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/bfminc?igsh=M2lsbmt6bWc0djNm" aria-label="social media">
                                            <i class="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="footer__info">
                            <div class="row section__row">
                                <div class="col-12 col-sm-6 col-lg-4 col-xl-4 section__col">
                                    <div class="footer__info-single">
                                        <div class="footer__info-single__thumb">
                                            <img src={locationImg} alt="BFM-Img" />
                                        </div>
                                        <div class="footer__info-single__content">
                                            <h5>{t('contactAdresTitle')}</h5>
                                            <p>{t('contactAdres')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 col-xl-4 section__col">
                                    <div class="footer__info-single">
                                        <div class="footer__info-single__thumb">
                                            <img src={contact} alt="BFM-Img" />
                                        </div>
                                        <div class="footer__info-single__content">
                                            <h5>Contact info</h5>
                                            <a href="mailto:infoexample@gmail.com" className='mb-0' dangerouslySetInnerHTML={{ __html: t('footerMail') }} />
                                            <a href="tel:+243817118432" dangerouslySetInnerHTML={{ __html: t('footerPhone') }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 col-xl-4 section__col">
                                    <div class="footer__info-single">
                                        <div class="footer__info-single__thumb">
                                            <img src={hoursone} alt="BFM-Img" />
                                        </div>
                                        <div class="footer__info-single__content">
                                            <h5>{t('contactHead')}</h5>
                                            <p>{t('contactHeadText')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="copyright">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-3 col-md-2 text-center text-sm-start">
                                    <button class="scroll-to-top" aria-label="scroll to top">
                                        <i class="icon-arrow-up"></i>
                                    </button>
                                </div>
                                <div class="col-12 col-sm-9 col-md-10 order-first order-sm-last">
                                    <p class="text-center">
                                        &copy;
                                        <span id="copyYear"></span>
                                        <a href="/">BFM</a>
                                        - Business Facilitation Market Inc.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer