import React from 'react'
import { useTranslation } from 'react-i18next';
import ctaImg from '../../Assets/images/delivery-man.png';
import arrow from '../../Assets/images/arrow.png';


function CtaQuotation() {

    const { t } = useTranslation();

    return (
        <section className="section delivery section--space-top" >
            <div className="container">
                <div className="row align-items-center section__row">
                    <div className="col-12 col-lg-8 section__col">
                        <div className="section__content">
                            <h6 className="title-anim">{t('ctatitle')}</h6>
                            <h2 className="title-anim">
                                {t('ctatext')}
                            </h2>
                            <div className="section__content-cta">
                                <a href="/contacts" className="btn btn--primary">
                                    {t('ctabtn')}
                                </a>
                            </div>
                            <img src={arrow} alt="pic bfm" className="d-none d-lg-block" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 section__col text-start text-lg-end">
                        <div className="video-wrap">
                            <a
                                href="https://www.youtube.com/watch?v=RvreULjnzFo"
                                rel="noopener noreferrer"
                                target="_blank"
                                title="video Player"
                                className="video-btn"
                            >
                                <i className="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row" id='quote'>
                    <div className="col-12">
                        <div className="delivery__inner">
                            <div className="row section section--space-top">
                                <div className="col-12 col-lg-4">
                                    <div className="delivery__thumb d-none d-lg-block">
                                        <img src={ctaImg} alt="pic bfm" data-speed="auto" />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="delivery__content gFadeBottom">
                                        <div className="delivery__content-meta">
                                            <h6 className="title-anim">{t('quotetitle')} </h6>
                                            <h2 className="title-anim">{t('quotesubtitle')}</h2>
                                        </div>
                                        <div className="delivery__content-form">
                                            <form action="#" method="post">
                                                <div className="single-input">
                                                    <input
                                                        type="text"
                                                        name="contact-name"
                                                        id="contactName"
                                                        placeholder={t('quoteinput1')}
                                                        required
                                                    />
                                                </div>
                                                <div className="group-input">
                                                    <div className="single-input">
                                                        <input
                                                            type="email"
                                                            name="contact-email"
                                                            id="contactEmail"
                                                            placeholder={t('quoteinput2')}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="single-input">
                                                        <input
                                                            type="number"
                                                            name="contact-phone"
                                                            id="contactPhone"
                                                            placeholder={t('quoteinput3')}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="group-input">
                                                    <div className="single-input">
                                                        <label htmlFor="selectFreight">{t('quoteservice')}</label>
                                                        <select id="selectFreight" className='form-select'>
                                                            <option value="select" className='selected' defaultValue={t('quoteservice')}>{t('quoteservice')}</option>
                                                            <option value={t('quoteservice1')}  >{t('quoteservice1')}</option>
                                                            <option value={t('quoteservice2')}  >{t('quoteservice2')}</option>
                                                            <option value={t('quoteservice3')}  >{t('quoteservice3')}</option>
                                                            <option value={t('quoteservice4')}  >{t('quoteservice4')}</option>
                                                            <option value={t('quoteservice5')}  >{t('quoteservice5')}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="section__content-cta">
                                                    <button type="submit" className="btn btn--primary">
                                                        {t('quotesubmit')}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaQuotation