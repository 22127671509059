import React from 'react'
// import Loader from '../../Components/loader/Loader';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/footer/Footer';
import { useTranslation } from 'react-i18next';
import mission from "../../Assets/images/about-three/mission.webp";
import vison from "../../Assets/images/about-three/Vision.webp";
import aboutIimg from "../../Assets/images/about-three/main.png";
import aboutIimg2 from "../../Assets/images/about-three/small.png";
import aboutIimg3 from "../../Assets/images/about-three/drag.png";



function AboutBfm() {

    const { t } = useTranslation();

    return (
        < >
            {/* <Loader /> */}
            <Header />
            <div className='smooth-wrapper'>
                <div className='smooth-content'>

                    {/* Banner About Bfm */}
                    <section className="banner banner-about">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner__content">
                                        <h2 className="animation__char_come">{t('abintro')}</h2>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="index.html">{t('breadcambhome')}</a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {t('breadcambcurent')}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* EndBanner About Bfm */}

                    {/* intro about */}
                    <section className="section about-three">
                        <div className="container">
                            <div className="row gaper align-items-center">
                                <div className="col-12 col-lg-5 col-xxl-6">
                                    <div className="about-three__thumb gFadeBottom">
                                        <div className="logi">
                                            <h2 className="title-anim">{t('abtitlemain')}</h2>
                                        </div>
                                        <div className="iner">
                                            <div className="interr">
                                                <img src={aboutIimg2} alt="About img" className="main-thumb" />
                                                <img src={aboutIimg} alt="About img" className="main-small fade_bottom" />
                                                <img src={aboutIimg3} alt="About img" className="drag" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 col-xxl-6">
                                    <div className="section__content about-three__content ">
                                        <h6 className="title-anim">
                                            Business Facilitation Market Inc.
                                        </h6>
                                        <h2 className="title-anim">
                                            {t('abtitle')}
                                        </h2>
                                        <div className="section__content-text text-anim">
                                            <p>
                                                {t('abtext')}
                                            </p>
                                        </div>
                                        <div className="about-three__inner fade_bottom">
                                            <h4>{t('abvaluestext')}</h4>
                                            <div className="about-three__inner-wrap">
                                                <div className="about-three__inner-wrap-list">
                                                    <ul>
                                                        <li>
                                                            <i className="fa-regular fa-circle-check"></i>
                                                            {t('abvalue1')}
                                                        </li>
                                                        <li>
                                                            <i className="fa-regular fa-circle-check"></i>
                                                            {t('abvalue2')}
                                                        </li>
                                                        <li>
                                                            <i className="fa-regular fa-circle-check"></i>
                                                            {t('abvalue3')}
                                                        </li>
                                                        <li>
                                                            <i className="fa-regular fa-circle-check"></i>
                                                            {t('abvalue4')}
                                                        </li>
                                                        <li>
                                                            <i className="fa-regular fa-circle-check"></i>
                                                            {t('abvalue5')}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="about-three__inner-wrap__meta">
                                                    <h5>
                                                        <span>10+</span>

                                                        <span className="in">{t('abvalue6')}</span>

                                                    </h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End intro about */}

                    {/* Mission and Vision */}
                    <section>
                        <div className="container py-5">

                            <div className="row m-4">
                                <div className="col-lg-5 align-self-center">
                                    <div className="lc-block about-img-shape position-relative"> <img className="img-fluid" src={mission} alt="bymmmm" />
                                    </div>
                                </div>
                                <div className="col-lg-6 offset-lg-1 my-2 align-self-center">
                                    <div className="lc-block">
                                        <div editable="rich">
                                            <h2>{t('abmission')}<br /></h2>
                                            <p>{t('abmissiontext')}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row m-4">
                                <div className="col-lg-6 offset-lg-1 my-2 align-self-center">
                                    <div className="lc-block">
                                        <div editable="rich">
                                            <h2>{t('abvision')}</h2>
                                            <p>{t('abvisiontext')}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-5 align-self-center">
                                    <div className="lc-block about-img-shape position-relative"> <img className="img-fluid" src={vison} alt="img by Dane Deaner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Mission and Vision */}



                    {/* Footer */}
                    <Footer />
                    {/* End footer */}
                </div>
            </div>
        </>
    )
}

export default AboutBfm