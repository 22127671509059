import React from 'react'
// import Loader from '../../Components/loader/Loader'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/footer/Footer'
import { useTranslation } from 'react-i18next';
import locationImg from "../../Assets/images/location-light.png";
import phoneLight from "../../Assets/images/phone-light.png";
import hourLight from "../../Assets/images/hour-light.png";

function Contacts() {

    const { t } = useTranslation();

    return (
        < >
            {/* <Loader /> */}
            <Header />
            <div className='smooth-wrapper'>
                <div className='smooth-content'>

                    {/* Banner contact */}
                    <section className="banner banner-contact">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner__content">
                                        <h2 className="animation__char_come">{t('contactIntro')}</h2>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="index.html">{t('breadcambhome')}</a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {t('contactIntro2')}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* EndBanner contact */}

                    {/* Form Contact */}
                    <section className="section section--space-top contact-main">
                        <div className="container">
                            <div className="row gaper">
                                <div className="col-12 col-lg-5 col-xl-5">
                                    <div className="contact-main__content">
                                        <div className="section__content ">
                                            <h6 className="title-anim">{t('contactTitle')}</h6>
                                            <h2 className="title-anim">
                                                {t('contactQuestion')}
                                            </h2>
                                            <div className="section__content-text text-anim">
                                                <p>
                                                    {t('ContactText')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="contact-main__content-wrapper ">
                                            <div className="contact-main__content-single gFadeBottom">
                                                <div className="contact-main__content-single__thumb">
                                                    <img src={locationImg} alt="Footer img" />
                                                </div>
                                                <div className="contact-main__content-single__meta">
                                                    <h5>
                                                        {t('contactAdresTitle')}
                                                    </h5>
                                                    <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1161.7345493442979!2d27.463116436878753!3d-11.652276116297204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19723eacfc300001%3A0x282253875a3d7bd1!2s2b%20Av.%20du%20lac%20Kipopo%2C%20Lubumbashi%2C%20R%C3%A9publique%20d%C3%A9mocratique%20du%20Congo!5e0!3m2!1sfr!2sru!4v1725974705352!5m2!1sfr!2sru" target="_blank" rel="noopener noreferrer">
                                                        {t('contactAdres')}</a>
                                                </div>
                                            </div>
                                            <div className="contact-main__content-single gFadeBottom">
                                                <div className="contact-main__content-single__thumb">
                                                    <img src={phoneLight} alt="Footer img" />
                                                </div>
                                                <div className="contact-main__content-single__meta">
                                                    <h5>
                                                        {t('contactPhoneTitle')}
                                                    </h5>
                                                    <p>
                                                        <a href="mailto:infoexampler@gmail.com">{t('contactEmail')}</a>
                                                        ,
                                                        <a href="tel:1-888-452-1505">{t('contactPhone')}</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="contact-main__content-single gFadeBottom">
                                                <div className="contact-main__content-single__thumb">
                                                    <img src={hourLight} alt="Footer img" />
                                                </div>
                                                <div className="contact-main__content-single__meta">
                                                    <h5>
                                                        {t('contactHead')}
                                                    </h5>
                                                    <p>{t('contactHeadText')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 col-xl-7">
                                    <div className="contact-main__form ">
                                        <div className="section__content">
                                            <h6 className="title-anim">{t('ContactQuestionIntro')}</h6>
                                            <h2 className="title-anim">
                                                {t('ContactQuestionText')}
                                            </h2>
                                            <div className="section__content-text text-anim">
                                                <p>
                                                    {t('ContactQuestionSubText')}
                                                </p>
                                            </div>
                                        </div>
                                        <form action="#" method="post">
                                            <div className="group-input">
                                                <div className="single-input">
                                                    <input
                                                        type="text"
                                                        name="contactMain-author-name"
                                                        id="coontactMainAuthorName"
                                                        placeholder={t('ContactFormInputName')}
                                                        required
                                                    />
                                                </div>
                                                <div className="single-input">
                                                    <input
                                                        type="email"
                                                        name="contactMain-email"
                                                        id="contactMainEmail"
                                                        placeholder={t('ContactFormInputEmail')}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="group-input">
                                                <div className="single-input">
                                                    <input
                                                        type="email"
                                                        name="contactMain-subject"
                                                        id="contactMainSubject"
                                                        placeholder={t('ContactFormInputSubject')}
                                                        required
                                                    />
                                                </div>
                                                <div className="single-input">
                                                    <input
                                                        type="number"
                                                        name="contactMain-phone"
                                                        id="coontactMainPhone"
                                                        placeholder={t('ContactFormInputPhone')}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="single-input">
                                                <textarea name="contactMain-message" id="contactMainMessage" placeholder={t('ContactFormInputTextarea')}></textarea>
                                            </div>
                                            <div className="section__content-cta">
                                                <button type="submit" className="btn btn--primary">{t('ContactFormInputTextButton')}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Form Contact */}

                    {/* MAp */}
                    <div className="map-wrapper section gFadeBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="map-inner">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1161.7345493442979!2d27.463116436878753!3d-11.652276116297204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19723eacfc300001%3A0x282253875a3d7bd1!2s2b%20Av.%20du%20lac%20Kipopo%2C%20Lubumbashi%2C%20R%C3%A9publique%20d%C3%A9mocratique%20du%20Congo!5e0!3m2!1sfr!2sru!4v1725974705352!5m2!1sfr!2sru" width="200" height="800" allowfullscreen="" className='border-none' title='BFM Position' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        <i className="fa-solid fa-location-dot"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Map */}

                    {/* Footer */}
                    <Footer />
                    {/* End footer */}
                </div>
            </div>
        </>
    )
}

export default Contacts