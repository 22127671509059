import React, { useEffect, useState } from 'react'
import logobfm from '../../Assets/images/logobfm.png';
import cart from "../../Assets/images/cart.png";
import LangageSwitcher from '../LangageSwitcher/LangageSwitcher';
import { useTranslation } from 'react-i18next';


export default function Header() {

    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    let lastScrollY = window.scrollY;

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {

        const handleScroll = () => {

            if (window.scrollY === 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
            lastScrollY = window.scrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);


    return (

        <>
            <header className="header header--primary head-one">

                <div className={`topbar topbar--primary  ${!isVisible ? 'd-none' : 'd-lg-block'} `}>

                    <div className="container">
                        <div className="topbar__inner min-full">
                            <div className="container unset-container">
                                <div className="row align-items-center">
                                    <div className="col-lg-9">
                                        <ul className="topbar__items">
                                            <li>
                                                <a href="mailto:example@hieq.com">
                                                    <i className="icon-email"></i>
                                                    info@bfmincorp.com
                                                </a>
                                            </li>
                                            <li>
                                                <i className="icon-clock"></i>
                                                {t('HeaderHour')}
                                            </li>
                                            <li>
                                                <a href="tel:253-556-7777">
                                                    <i className="icon-phone"></i>
                                                    {t('headerPhone')}
                                                </a>
                                            </li>

                                            <li>
                                                {window.innerWidth > 768 ? <LangageSwitcher /> : ""}

                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-3">
                                        <ul className="social justify-content-end pr-12">
                                            <li>
                                                <a href="https://www.facebook.com/share/asHeEo6dQ8KiGX9J/?mibextid=LQQJ4d" aria-label="social media">
                                                    <i className="fa-brands fa-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/bfminc/" aria-label="social media">
                                                    <i className="fa-brands fa-linkedin"></i>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="https://www.instagram.com/bfminc?igsh=M2lsbmt6bWc0djNm" aria-label="social media">
                                                    <i className="fa-brands fa-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className={`navbar navbar-expand-lg  py-md-0 ${!isVisible ? "header-active" : ""}`} >
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src={logobfm} className='nav__logo' alt="logobfm" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                                <li className="nav__menu-item ">
                                    <a className="nav__menu-link hide-nav" href="/">
                                        {t('navbarHome')}
                                    </a>

                                </li>
                                <li className="nav__menu-item">
                                    <a className="nav__menu-link hide-nav" href="/about">
                                        {t('navbarAbout')}

                                    </a>
                                </li>

                                <li className="nav__menu-item">
                                    <a className="nav__menu-link hide-nav" href="/services">
                                        {t('navbarService')}
                                    </a>
                                </li>
                                <li className="nav__menu-item">
                                    <a className="nav__menu-link hide-nav" href="/contacts">
                                        {t('navbarContact')}
                                    </a>
                                </li>

                                {
                                    window.innerWidth < 768 ? <li className='av__menu-item'><LangageSwitcher /></li> : ""
                                }

                            </ul>

                            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                                <div className="nav__uncollapsed-item d-none d-md-flex">
                                    <a href="/#quote" className="btn btn--primary" onClick={() => handleClickScroll("quote")}>
                                        Get A Quote
                                    </a>
                                </div>


                            </ul>

                        </div>
                    </div>
                </nav>
                <div className="backdrop"></div>


                {/* <div className="search-popup">
                    <button className="close-search style-two">
                        <span className="fa-solid fa-xmark"></span>
                    </button>
                    <button className="close-search">
                        <span className="fa-solid fa-xmark"></span>
                    </button>
                    <form method="post" action="#">
                        <div className="form-group">
                            <input
                                type="search"
                                name="search-field"
                                value=""
                                placeholder="Search Here"
                                required=""
                            />
                            <button type="submit">
                                <i className="icon-search"></i>
                            </button>
                        </div>
                    </form>
                </div> */}

            </header>

            {/* sidebar car */}
            <div className="sidebar-cart">
                <div className="der">
                    <button className="close-cart">
                        <span className="close-icon">X</span>
                    </button>
                    <h2>
                        Shopping Bag
                        <span className="count">2</span>
                    </h2>
                    <div className="cart-items">
                        <div className="cart-item-single">
                            <div className="cart-item-thumb">
                                <a href="product-single.html">
                                    <img src={cart} alt="pic bfm" />
                                </a>
                            </div>
                            <div className="cart-item-content">
                                <h6 className="title-anim">
                                    <a href="product-single.html">Product One</a>
                                </h6>
                                <p className="price">
                                    $
                                    <span className="item-price">34.99</span>
                                </p>
                                <div className="measure">
                                    <button aria-label="decrease item" className="quantity-decrease">
                                        <i className="fa-solid fa-minus"></i>
                                    </button>
                                    <span className="item-quantity">0</span>
                                    <button aria-label="add item" className="quantity-increase">
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <button aria-label="delete item" className="delete-item">
                                <i className="fa-solid fa-trash"></i>
                            </button>
                        </div>

                        <div className="cart-item-single">
                            <div className="cart-item-thumb">
                                <a href="product-single.html">
                                    <img src={cart} alt="pic bfm" />
                                </a>
                            </div>
                            <div className="cart-item-content">
                                <h6 className="title-anim">
                                    <a href="product-single.html">Product One</a>
                                </h6>
                                <p className="price">
                                    $
                                    <span className="item-price">34.99</span>
                                </p>
                                <div className="measure">
                                    <button aria-label="decrease item" className="quantity-decrease">
                                        <i className="fa-solid fa-minus"></i>
                                    </button>
                                    <span className="item-quantity">0</span>
                                    <button aria-label="add item" className="quantity-increase">
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <button aria-label="delete item" className="delete-item">
                                <i className="fa-solid fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div className="totals">
                        <div className="subtotal">
                            <span className="label">Subtotal:</span>
                            <span className="amount">
                                $
                                <span className="total-price">0.00</span>
                            </span>
                        </div>
                    </div>
                    <div className="action-buttons">
                        <a className="view-cart-button" href="cart.html" aria-label="go to cart">
                            Cart
                        </a>
                        <a className="checkout-button" href="checkout.html" aria-label="go to checkout">
                            Checkout
                            <i className="fa-solid fa-arrow-right-long"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="cart-backdrop"></div>
        </>
    )
}
