import React, { useEffect, useState } from 'react'
import Loader from '../../Components/loader/Loader';
import Header from '../../Components/Header/Header';
import image1 from "../../Assets/images/banner/9.png";
import about1 from '../../Assets/images/about-three/main2.png';
import about2 from '../../Assets/images/overview/overview-thumb-two.png';
import about3 from '../../Assets/images/overview/overview-thumb-three.png';
import one from '../../Assets/images/service/one.png';
import two from '../../Assets/images/service/two.png';
import three from '../../Assets/images/service/three.png';
import Footer from '../../Components/footer/Footer';
import { useTranslation } from 'react-i18next';
import CtaQuotation from '../../Components/CtaQuotation/CtaQuotation';



function Home() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Adjust the timeout duration as needed
    }, []);

    return (
        <>

            <div className='smooth-wrapper'>
                <div className='smooth-content'>
                    {
                        loading ?
                            <Loader /> :

                            <>
                                {/* Header */}
                                <Header />
                                {/* End header */}

                                {/* Banner */}
                                <div className="baner">
                                    <div className="container">
                                        <div className="row justify-content-center justify-content-xl-start">
                                            <div className="col-12 col-md-8 col-lg-7 col-xl-6 offset-xl-6">
                                                <div className="banner-one-slider">
                                                    <div className="banner__content">
                                                        <h5>{t('welcome')} </h5>
                                                        <h1>{t('hero1')}</h1>
                                                        <div className="banner__content-text">
                                                            <p dangerouslySetInnerHTML={{ __html: t('hero2') }} />
                                                        </div>
                                                        <div className="banner__content-cta">
                                                            <a href="/services" className="btn btn--primary">
                                                                {t('linkservice')}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-one__car">
                                        <img src={image1} alt="pic bfm" />
                                    </div>
                                </div>
                                {/* End Banner */}

                                {/* About BFM CEO */}
                                <section className="section overview ext-over">
                                    <div className="container">
                                        <div className="row align-items-center section__row">
                                            <div className="col-12 col-lg-6 col-xl-5 section__col">
                                                <div className="section__content">
                                                    <h6 className="title-anim">{t('abouttitle')}</h6>
                                                    <h2 className="title-anim">
                                                        {t('abouttitle2')}
                                                    </h2>
                                                    <div className="section__content-text text-anim">
                                                        <p className="secondary-text">
                                                            {t('aboutsubtitle')}
                                                        </p>
                                                        <p>
                                                            {t('aboutexte')}
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-xl-1 section__col">
                                                <div className="overview__thumb gFadeBottom">
                                                    <img src={about1} alt="pic bfm" className="main-thumb" />
                                                    <div className="two fade_bottom">
                                                        <img src={about2} alt="pic bfm" />
                                                    </div>
                                                    <img src={about3} alt="pic bfm" className="three" />
                                                    <div className="overview__thumb-content">
                                                        <h3>{t('aboutcounter')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* En about */}

                                {/* Service */}
                                <section className="section blog cmn-animation">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xxl-7">
                                                <div className="section__header">
                                                    <h2 className="title-anim">{t('servicetitle')}</h2>
                                                    <p className="title-anim">
                                                        {t('servicesubtitle')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center section__row">
                                            <div className="col-12 col-md-6 col-lg-4 section__col">
                                                <div className="blog__single cmn-animation-item">
                                                    <div className="blog__single-thumb">
                                                        <a href="blog-details.html">
                                                            <img src={one} alt="pic bfm" />
                                                        </a>
                                                    </div>
                                                    <div className="blog__single-content">
                                                        <div className="blog__single-content__thumb">
                                                            <span>
                                                                <i className="fas fa-user-cog"></i>
                                                            </span>
                                                        </div>
                                                        <div className="blog__single-content__content">
                                                            <p>
                                                                {t('service1text')}
                                                            </p>
                                                            <h4>
                                                                <a href="/bfm-movers">{t('service1title')}</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 section__col">
                                                <div className="blog__single cmn-animation-item">
                                                    <div className="blog__single-thumb">
                                                        <a href="blog-details.html">
                                                            <img src={two} alt="pic bfm" />
                                                        </a>
                                                    </div>
                                                    <div className="blog__single-content">
                                                        <div className="blog__single-content__thumb">
                                                            <span>
                                                                <i className="fas fa-truck-fast"></i>
                                                            </span>
                                                        </div>
                                                        <div className="blog__single-content__content">
                                                            <p>
                                                                {t('service2text')}
                                                            </p>
                                                            <h4>
                                                                <a href="/bfm-logistics">{t('service2title')}</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 section__col">
                                                <div className="blog__single cmn-animation-item">
                                                    <div className="blog__single-thumb">
                                                        <a href="blog-details.html">
                                                            <img src={three} alt="pic bfm" />
                                                        </a>
                                                    </div>
                                                    <div className="blog__single-content">
                                                        <div className="blog__single-content__thumb">
                                                            <span>
                                                                <i className="fas fa-code"></i>
                                                            </span>
                                                        </div>
                                                        <div className="blog__single-content__content">
                                                            <p>
                                                                {t('service3text')}
                                                            </p>
                                                            <h4>
                                                                <a href="/bfm-developpers">{t('service3title')}</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                                {/* End service */}

                                {/* Call to action */}
                                <CtaQuotation />
                                {/* End call to action */}

                                {/* Partenaire */}
                                {/* <Partenaires /> */}
                                {/* End partenaire */}

                                {/* Footer */}
                                <Footer />
                                {/* End footer */}
                            </>
                    }


                </div>
            </div>
        </>
    )
}

export default Home