import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../Assets/images/logobfm.png';

function BfmSide() {

    const { t, langue } = useTranslation();
    const pdfUrl = langue === 'En' ?
        require("../../Assets/profils/BFMPROFILE2024ENGLISH.pdf") :
        require("../../Assets/profils/BFMPROFIL2024.pdf");

    return (
        <div className="col-12 col-xl-4 order-last order-xl-first">
            <div className="service-details__sidebar">
                <div className="widget widget-light  gFadeBottom">
                    <h4>Services</h4>
                    <ul className="widget-list">
                        <li>
                            <a href="/bfm-movers">
                                <i className="icon-warehouse"></i>
                                BFM MOVERS
                            </a>
                        </li>
                        <li>
                            <a href="/bfm-logistics">
                                <i className="icon-aeroplane"></i>
                                {t('BfmServ')}
                            </a>
                        </li>
                        <li>
                            <a href="/bfm-developpers">
                                <i className="icon-ship"></i>
                                {t('BfmDev')}
                            </a>
                        </li>

                    </ul>
                </div>
                <div className="widget widget-dark  gFadeBottom">
                    <div className="widget-head">
                        <h4>{t('BfmDownloads')}</h4>
                        <p>{t('BfmDownloadsIntro')}</p>
                    </div>
                    <div className="widget-dark__group">
                        <div className="widget-dark__single">
                            {/* <div className="widget-dark__single-thumb">
                                                        <img src="Assets/images/service/service-details-doc.png" alt="BFM-Img" />
                                                    </div> */}
                            <div className="widget-dark__single-content">
                                <h5>
                                    <a href={pdfUrl} download target='_blanc' rel='noreferrer'>
                                        {t('BfmDownloadsDoc')}
                                    </a>
                                </h5>
                                <p>-2024</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="widget widget-contact  gFadeBottom">
                    <div className="log">
                        <a href="/">
                            <img src={logo} alt="BFM-Img" />
                        </a>
                    </div>
                    <div className="content">
                        <h4>
                            {t('BfmDownloadsText')}
                        </h4>
                    </div>
                    <div>
                        <a href="/contacts" className="btn btn--primary">{t('BfmDownloadsBtn')}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BfmSide