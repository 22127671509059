import React from 'react'
// import Loader from '../../Components/loader/Loader';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/footer/Footer';
import intro from '../../Assets/images/banner/bfm-mover-intro2.jpg';
import { useTranslation } from 'react-i18next';
import BfmSide from '../../Components/BfmSide/BfmSide';
import CtaQuotation from '../../Components/CtaQuotation/CtaQuotation';

function BfmMovers() {

    const { t } = useTranslation();

    return (
        < >
            {/* <Loader /> */}
            <Header />
            <div className='smooth-wrapper'>
                <div className='smooth-content'>

                    {/* Banner services */}
                    <section className="banner banner-bfm-movers">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner__content">
                                        <h2 className="animation__char_come">Bfm Movers</h2>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="/">{t('breadcambhome')}</a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    Bfm_movers
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* EndBanner services */}

                    {/* Service details */}
                    <section className="section  service-details-main">
                        <div className="container">
                            <div className="row justify-content-center gaper">
                                <BfmSide />

                                <div className="col-12 col-xl-8">
                                    <div className="service-details__content">
                                        <div className="service-details-thumb  gFadeBottom">
                                            <img src={intro} alt="BFM-Img" />
                                        </div>
                                        <div className="service-details__content-group text-anim">
                                            <h4>BFM MOVERS <br />{t('BfmTitle')}</h4>

                                            <p dangerouslySetInnerHTML={{ __html: t('BfmText') }} />

                                        </div>

                                        <div className="service-details__content-group ">
                                            <h4>{t('BfmMoversService')}</h4>
                                            <ul className="service-details__content-group-icon-list">
                                                <li>
                                                    <strong>{t('BfmMoversService1')}</strong>
                                                </li>
                                                <li>
                                                    <strong>{t('BfmMoversService2')}</strong>
                                                </li>
                                                <li>
                                                    <strong>{t('BfmMoversService3')}</strong>
                                                </li>
                                                <li>
                                                    <strong>{t('BfmMoversService4')}</strong>
                                                </li>
                                                <li>
                                                    <strong>{t('BfmMoversService5')}</strong>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Service details */}

                    {/* Call to action */}
                    <CtaQuotation />
                    {/* End call to action */}

                    {/* Footer */}
                    <Footer />
                    {/* End footer */}
                </div>
            </div>
        </>
    )
}

export default BfmMovers