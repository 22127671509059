import React from 'react'
import one from '../../Assets/images/service/one.png';
import two from '../../Assets/images/service/two.png';
import three from '../../Assets/images/service/three.png';
import four from '../../Assets/images/service/four.webp';
import { useTranslation } from 'react-i18next';

function ListeServices() {

    const { t } = useTranslation();

    return (
        <section className="section blog cmn-animation">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xxl-7">
                        <div className="section__header">
                            <h2 className="title-anim">{t('servicetitle')}</h2>
                            <p className="title-anim">
                                {t('servicesubtitle')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center section__row">
                    <div className="col-12 col-md-6 col-lg-3 section__col">
                        <div className="blog__single cmn-animation-item">
                            <div className="blog__single-thumb">
                                <a href="blog-details.html">
                                    <img src={one} alt="pic bfm" />
                                </a>
                            </div>
                            <div className="blog__single-content">
                                <div className="blog__single-content__thumb">
                                    <span>
                                        <i className="icon-aeroplane"></i>
                                    </span>
                                </div>
                                <div className="blog__single-content__content">
                                    <p>
                                        {t('service1text')}
                                    </p>
                                    <h4>
                                        <a href="/bfm-movers">{t('service1title')}</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 section__col">
                        <div className="blog__single cmn-animation-item">
                            <div className="blog__single-thumb">
                                <a href="blog-details.html">
                                    <img src={two} alt="pic bfm" />
                                </a>
                            </div>
                            <div className="blog__single-content">
                                <div className="blog__single-content__thumb">
                                    <span>
                                        <i className="fas fa-truck-fast"></i>
                                    </span>
                                </div>
                                <div className="blog__single-content__content">
                                    <p>
                                        {t('service2text')}
                                    </p>
                                    <h4>
                                        <a href="/bfm-movers">{t('service2title')}</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 section__col">
                        <div className="blog__single cmn-animation-item">
                            <div className="blog__single-thumb">
                                <a href="blog-details.html">
                                    <img src={three} alt="pic bfm" />
                                </a>
                            </div>
                            <div className="blog__single-content">
                                <div className="blog__single-content__thumb">
                                    <span>
                                        <i className="fas fa-code"></i>
                                    </span>
                                </div>
                                <div className="blog__single-content__content">
                                    <p>
                                        {t('service3text')}
                                    </p>
                                    <h4>
                                        <a href="/bfm-movers">{t('service3title')}</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 section__col">
                        <div className="blog__single cmn-animation-item">
                            <div className="blog__single-thumb">
                                <a href="blog-details.html">
                                    <img src={four} alt="pic bfm" />
                                </a>
                            </div>
                            <div className="blog__single-content">
                                <div className="blog__single-content__thumb">
                                    <span>
                                        <i className="fas fa-broom"></i>
                                    </span>
                                </div>
                                <div className="blog__single-content__content">
                                    <p>
                                        {t('servicefeatures4Text')}
                                    </p>
                                    <h4>
                                        <a href="/bfm-movers">{t('servicefeatures4')}</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default ListeServices