import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import BfmMovers from './Pages/Services/Bfm_movers';
import BfmLogistics from './Pages/Services/BfmLogistics';
import BfmDev from './Pages/Services/BfmDev';
import AboutBfm from './Pages/About/AboutBfm';
import Contacts from './Pages/Contacts/Contacts';
import { useTranslation } from 'react-i18next';

function App() {

    const { i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage); // Charger la langue sauvegardée
    }, [i18n]);


    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutBfm />} />
                <Route path="/services" element={<Services />} />
                <Route path="/bfm-movers" element={<BfmMovers />} />
                <Route path="/bfm-logistics" element={<BfmLogistics />} />
                <Route path="/bfm-developpers" element={<BfmDev />} />
                <Route path="/contacts" element={<Contacts />} />
                {/* <Route path="*" element={<Notfound />} />  Catch-all route */}
            </Routes>
        </Router>
    );
}

export default App;
