import React from 'react'

function Loader() {
    return (
        <div id="preloader">
            <div id="ctn-preloader" className="ctn-preloader">
                <div className="animation-preloader">
                    <div className="spinner"></div>
                    <div className="txt-loading">
                        <span data-text-preloader="B" className="letters-loading"> B</span>
                        <span data-text-preloader="F" className="letters-loading"> F</span>
                        <span data-text-preloader="M" className="letters-loading"> M</span>

                    </div>
                </div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>
        </div>
    )
}

export default Loader