import React from 'react';
import { useTranslation } from 'react-i18next';



function LangageSwitcher() {

    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage); // Changer la langue
        localStorage.setItem('language', selectedLanguage)
    };

    return (

        <select onChange={changeLanguage} defaultValue={i18n.language} className='form-select'>
            <option value="en">
                <i><img src="https://flagcdn.com/16x12/ua.png" alt="" />En </i>
            </option>
            <option value="fr">
                <span className="flag-icon flag-icon-fr"></span> Fr
            </option> {/* Tu peux ajouter d'autres langues ici */}
        </select>

    );

}

export default LangageSwitcher;