import React from 'react'
// import Loader from '../../Components/loader/Loader';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/footer/Footer';
import intro from '../../Assets/images/banner/bfm-dev.png';
import BfmSide from '../../Components/BfmSide/BfmSide';
import { useTranslation } from 'react-i18next';
import CtaQuotation from '../../Components/CtaQuotation/CtaQuotation';

function BfmDev() {

    const { t } = useTranslation();

    return (
        < >
            {/* <Loader /> */}
            <Header />
            <div className='smooth-wrapper'>
                <div className='smooth-content'>

                    {/* Banner services */}
                    <section className="banner banner-bfm-dev">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner__content">
                                        <h2 className="animation__char_come">{t('BfmDev')}</h2>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="/">{t('breadcambhome')}</a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {t('BfmDev')}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* EndBanner services */}

                    {/* Service details */}
                    <section className="section  service-details-main">
                        <div className="container">
                            <div className="row justify-content-center gaper">
                                <BfmSide />

                                <div className="col-12 col-xl-8">
                                    <div className="service-details__content">
                                        <div className="service-details-thumb  gFadeBottom">
                                            <img src={intro} alt="BFM-Img" />
                                        </div>
                                        <div className="service-details__content-group text-anim">
                                            <h4>{t('BfmDev')}</h4>

                                            <p dangerouslySetInnerHTML={{ __html: t('BfmDevText') }} />


                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Service details */}

                      {/* Call to action */}
                      <CtaQuotation />
                    {/* End call to action */}

                    {/* Footer */}
                    <Footer />
                    {/* End footer */}
                </div>
            </div>
        </>
    )
}

export default BfmDev