import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import En from './Langs/En.json';
import Fr from './Langs/Fr.json';

i18n

    .use(LanguageDetector)
    .use(initReactI18next)
    .init({

        resources: {

            en: {

                translation: En,

            },

            fr: {

                translation: Fr,

            },

        },

        lng: 'en',

        fallbackLng: 'en',

        interpolation: {

            escapeValue: false,

        },

    });
export default i18n;